import React from "react"

import FullScreenLayout from "../components/FullScreenLayout"
import Image from "../components/image"
import SEO from "../components/seo"
import Header from "../components/Header"

import { HeroText } from "./index.module.scss"
import { Link } from "gatsby"

const IndexPage = () => (
  <FullScreenLayout>
    <SEO title="Home" />
    <Image />
    <div className="flex-column" style={{ height: "100vh", display: "flex" }}>
      <Header></Header>
      <div
        className={`${HeroText} d-flex flex-column align-items-center justify-content-center`}
        style={{
          flex: 1,
          color: "var(--white)",
          textAlign: "center",
          textShadow: "0px 0px 2px #000000",
        }}
      >
        <p>
          we help companies large and
          <br />
          small deliver human-centered
          <br />
          technology for less
        </p>
        <Link to="/services" className="mt-2 btn btn-primary btn-lg">
          Find out how &raquo;
        </Link>
      </div>
    </div>
  </FullScreenLayout>
)

export default IndexPage
